import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldTuesday: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Tuesday?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_tuesday.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Tuesday?</h1>
          <h2>
            Not sure if pulling Tuesday is the right move? No worries! Check our
            review and guide for her!
          </h2>
          <p>
            Last updated: <strong>01/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="tuesday"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>You should pull Tuesday if:</p>
        <ul>
          <li>
            You want to play [Poison] teams, which will become even stronger in
            the future (2.3). Note that Tuesday will be the character that makes
            [Poison] teams work.
          </li>
          <li>You enjoy Damage Over Time effects.</li>
          <li>
            You like playing Sotheby and plan to unlock her Euphoria upgrade in
            2.3.
          </li>
          <li>You like horror movies, maids, and Texan accents .</li>
        </ul>
        <p>
          As of 2.3, Tuesday is an S-tier Sub Carry with amazing [Poison]
          application, an Array that extends [Poison] duration, and the ability
          to make [Poison] critically hit. She even allows you to store excess
          Moxie as a nice added bonus.
        </p>
        <p>
          Overall, Tuesday is a great investment that will pay off in 2.3 with
          the release of Willow, the [Poison] team’s new damage dealer. In
          addition, Sotheby becomes an indomitable healer after her Euphoria
          buffs who works perfectly as the [Poison] team’s Sustain.
        </p>
        <SectionHeader title="Review" />
        <p>
          Tuesday’s release ushers in the new age of [Poison] teams to unlock
          true Damage-Over-Time gameplay.
        </p>

        <p>
          Her Insight I grants [Fear’s Cradle], a [Pos Status] that allows all
          allies’ [Poison] DMG to critically hit. Whenever an ally action
          applies [Stats Down], [Neg Status], or [Control, each stack has a 50%
          chance to apply 1 [Poison] for a round. Insight I also increases the
          Genesis DMG dealt by Tuesday’s effects.
        </p>
        <p>
          Her Insight III grants [Room Service], another [Pos Status] that
          increases all allies’ Max Moxie by 5. Since the Moxie needed for each
          Ultimate stays at 5, this allows allies to store excess Moxie and wait
          to cast their Ultimates at a more opportune time. This is especially
          important for Sotheby, as Tuesday’s Array allows her Ultimate to
          resolve all [Poison] stacks without depleting them. Insight III also
          gives Tuesday 2 Moxie right off the bat, allowing her to activate her
          Array quickly.
        </p>
        <p>
          Speaking of Tuesday’s Array, her Ultimate “Please, Come On In” sets up
          [Horror Story Night] for 3 rounds, during which [Poison] does not
          decrease in stacks even when it deals damage at the end of the round.
          This allows Tuesday and her allies to stack up an enormous amount of
          [Poison] at once. When her Array ends, all [Poison] stacks get
          triggered at once for a massive onslaught of Genesis DMG.
        </p>
        <p>
          To start playing Tuesday, you’d usually want to open with her
          Incantation II, “In Mother’s Arms” This increases all enemies’ Genesis
          DMG Taken and decreases their Critical Resist Rate for 2 rounds. The
          Crit Resist shred is even stronger against enemies with [Poison]. This
          skill’s 2 [Stats Down] effects can also trigger [Fear’s Cradle] and
          apply [Poison].
        </p>
        <p>
          After that, you’ll want to apply [Poison] with “The Horror’s Delight”.
          At first glance, this only applies 2 rounds of [Poison] to a single
          target. However, this is a special type of [Poison] which bounces to
          another enemy after doing damage at the end of the round. The number
          of times this [Poison] can bounce is determined by this skill's
          incantation rank. With her Array up, this skill has the potential to
          multiply [Poison] stacks on the enemy to a terrifying degree.
        </p>
        <p>
          As the enabler of a new team type, Tuesday plays her role very well.
          Her kit is great for long fights against a big boss with small minions
          and offers a great amount of burst damage when her Array ends.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    THE backbone of the new age [Poison] team. Tuesday is
                    extremely necessary if you plan on playing this archetype.
                  </li>
                  <li>Extremely efficient [Poison] application.</li>
                  <li>Lots of Genesis DMG.</li>
                  <li>
                    Strong Critical Resist Rate shred, allowing your characters
                    to land critical hits much more often.
                  </li>
                  <li>
                    Automatically enables [Poison] to crit just by being on
                    field.
                  </li>
                  <li>
                    Also automatically allows you to overcap all characters’
                    Moxie by 5, making her very comfortable to play.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    You have to wait until 2.3 for her best team (Willow +
                    post-Euphoria Sotheby).
                  </li>
                  <li>
                    She plays best within the [Poison] niche; her Array doesn’t
                    offer any utility to non-Poison teams.
                  </li>
                  <li>Useless against [Poison]-immune enemies.</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>Tuesday is complete at P0.</p>
        <p>
          Her portrays mostly increase her own [Poison] application and damage,
          with P2 being the exception—it strengthens her Genesis DMG Taken
          debuff and Crit Res Rate shred.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          As mentioned earlier, Tuesday plays well with any [Poison] character.
          Notably, Sotheby and 2.3’s Willow form a complete [Poison] team with
          Tuesday. Unfortunately, Jessica doesn’t need the sheer amount of
          [Poison] application Tuesday can offer, so she gets superseded as the
          true [Poison] DPS. In the meantime, though, Jessica still plays with
          Tuesday very well—it’s Tuesday who doesn’t really need Jessica.
        </p>
        <p>
          For the 2 Rabies players out there, Tuesday’s Array offers a way for
          Rabies’ [Poison] stacks to stick around longer than 1 pitiful turn.
          Unlike Jessica, who prefers to apply [Poison] automatically at the
          start of every 2 rounds, Rabies applies [Poison] through active
          actions and thus can trigger [Fear’s Cradle] for even more [Poison].
        </p>
        <p>
          Lastly, any mass debuffer who applies [Stats Down], [Neg Stat], or
          [Control] by taking any action works well with Tuesday. If Isolde
          applies [Burn] with her [Intermezzo] FUA at the beginning of the round
          with [Preignition] it triggers Tuesday’s Insight I. However, stuff
          like Tooth Fairy’s Crit DEF & Crit Resist Rate shred doesn’t count
          because it’s not an action.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="sotheby"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="willow"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Okay synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="jessica"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="kanjira"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="rabies"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Tuesday:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="and-the-maid-says"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="blasphemer-of-night"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="her-second-life"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          "And the Maid Says" is Tuesday's signature psychube and makes her
          Array an even better support tool for [Poison] team comps. This
          psychube enables the Array to increase all allies’ [Poison] damage by
          4%~8% and increase Tuesday’s by an additional 4%~8% (depending on
          Amplification).
        </p>
        <p>
          As Tuesday applies a lot of [Poison], "Blasphemer of Night" is
          trivially easy for her to activate.
        </p>
        <p>
          "Her Second Life" increases Tuesday's ATK (which affects her [Poison]
          damage) while also offering some healing; this is helpful in teams
          without post-Euphoria Sotheby’s monstrous amounts of sustain.
        </p>
        <SectionHeader title="Resonance" />
        <h6>Level 7</h6>
        <StaticImage
          src="../../../images/reverse/generic/tuesday_r_1.webp"
          alt="Guides"
        />
        <h6>Level 9</h6>
        <StaticImage
          src="../../../images/reverse/generic/tuesday_r_2.webp"
          alt="Guides"
        />
        <h6>Level 10</h6>
        <StaticImage
          src="../../../images/reverse/generic/tuesday_r_3.webp"
          alt="Guides"
        />
        <br />
        <StaticImage
          src="../../../images/reverse/generic/tuesday_r_4.webp"
          alt="Guides"
        />
        <h5>Resonance Pattern Recommendations:</h5>
        <p>Hyper - ATK (Recommended)</p>
        <StaticImage
          src="../../../images/reverse/generic/tuesday_r_5.webp"
          alt="Guides"
        />
        <p>Delirament - Generalized</p>

        <StaticImage
          src="../../../images/reverse/generic/tuesday_r_6.webp"
          alt="Guides"
        />
        <p>
          Tuesday’s [Poison] scales off of her ATK, so Hyper is the best pick
          here. If you want more survivability, go for Delirament.
        </p>
        <SectionHeader title="Teams" />
        <p>
          A few suggestions are listed below. 3-People teams are highlighted,
          with the 4th slot added at the side to provide a 4-P variation of the
          same team or even insights on possible variations of the 3-P team.
        </p>
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            One Flew Over the Old Motel
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="jessica"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="tuesday"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Sub-DPS</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="sotheby"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options:{' '}
                <strong>6, Isolde, Voyager, Bkornblume, Diggers</strong>
              </li>
              <li>
                A hybrid Jessica/Genesis DMG team to tide you over until 2.3
                arrives.
              </li>
              <ul>
                <li>
                  Jessica benefits from Tuesday’s [Poison] to unlock her higher
                  skill multipliers. “White Blankie” wants 3 stacks of [Poison],
                  which Tuesday can easily provide.
                </li>
                <li>
                  Tuesday’s Array allows Sotheby to cast her Ultimate to deal
                  lots of damage without turning off Jessica’s skill
                  multipliers.
                </li>
                <li>
                  Sotheby’s [Poison] application is pretty good. Her healing is
                  tricky because it activates at the beginning of the round, so
                  it might be a good idea to bring some form of pseudo-sustain
                  like running “Her Second Life” on Tuesday.
                </li>
                <li>
                  6 is a great 4-P teammate as he gives some sustain through
                  [Vitalize I] while also being able to apply mass debuffs for
                  more [Poison].
                </li>
                <li>
                  Isolde notably shreds Crit DEF, not Crit Res Rate, so she
                  works pretty well with Tuesday. Her Reality DEF shred is also
                  great for Jessica.
                </li>
                <li>
                  As Voyager’s [Counter] deals Genesis DMG, Tuesday’s Genesis
                  DMG Taken debuff really helps with her damage output. Voyager
                  also offers mass [Confusion] application, which stacks with
                  Tuesday’s Crit Res Rate shred to stack up an ungodly amount of
                  Crit Rate potential.
                </li>
                <li>
                  Bkornblume is a great budget choice you probably already have
                  built. Her “Prying Ear” debuff is amazing for Tuesday’s extra
                  [Poison] application and her Reality DEF shred helps out with
                  Jessica’s damage.
                </li>
                <li>
                  Diggers notably shreds both Mental and Reality DEF (albeit to
                  a lesser extent than specialized debuffers). Since Tuesday
                  deals Mental DMG while the rest of the team does Reality DMG,
                  Diggers can benefit both sides. His entire kit is able to proc
                  Tuesday’s extra [Poison] application (except Rank 1 Skill 1),
                  with “Bubblism” being a standout option.
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Fresh Poison (2.3)<span className="tag">[CN]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="willow"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="tuesday"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Sub-DPS</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="sotheby"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options:{' '}
                <strong>
                  Kakania, Mercuria, Kanjira , Rabies, Twins Sleep
                </strong>
              </li>
              <li>The [Poison] meta team in 2.3.</li>
              <ul>
                <li>
                  Willow is able to constantly trigger [Poison] without
                  decreasing its duration, allowing her to capitalize on all the
                  [Poison] stacks Tuesday inflicts. Willow is also able to
                  convert [Poison] into [Hag’s Bane], a special kind of [Poison]
                  that ramps up in damage each time it’s triggered each turn.
                </li>
                <li>
                  Sotheby becomes an incredibly competent healer with her
                  Euphoria buff and also applies a bit more [Poison] that does
                  more damage. She’s the perfect sustain for the [Poison] team.
                </li>
                <li>
                  In 4-P content like Mane’s Bulletin Raids, bringing Kakania is
                  always a great option. This team does especially well against
                  “Darkness of the Abyss” in particular, since it’s extra
                  vulnerable to Genesis DMG. Kakania makes this team tankier
                  while also being able to capitalize on Tuesday’s Genesis DMG
                  Taken debuff.
                </li>
                <li>
                  Any character that likes to crit will love Mercuria. Even
                  though this team doesn’t cast a whole lot of high rank
                  incantations, Mercuria’s baseline Crit DMG buff (which she
                  gets just by standing around) is great for this team. Her Crit
                  Rate buff also really helps characters like Sotheby get
                  [Poison] crits. This team also really doesn’t care about the
                  Incantation Might Mercuria gives at 16 [Cosmic Energy], making
                  Mercuria a great AFK support for this AP-hungry team.
                </li>
                <li>
                  Kanjira has decent [Poison] application alongside some
                  [Confusion], making her a pretty good backup option for this
                  team that loves [Poison] and crit.
                </li>
                <li>
                  Rabies is a great budget [Poison] applicator who works best in
                  AoE scenarios. Since Willow can convert his 1-round [Poison]
                  into her own [Hag’s Bane], the only thing Rabies needs to do
                  is spew [Poison]—which he does very well.
                </li>
                <li>
                  Twins Sleep, besides being thematically relevant to Tuesday’s
                  whole horror vibe, actually has a surprisingly synergistic kit
                  with her. While Tuesday decreases Crit Resist Rate, Twins
                  Sleep target Crit DEF. They also have access to [Weakness],
                  [Nightmare], and Mental DEF shred, making them a surprisingly
                  good budget option for a backup debuffer.
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Budget Costume Party <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="rabies"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="tuesday"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Sub-DPS</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="bkornblume"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Sotheby, Yenisei, Kakania</strong>
              </li>
              <li>
                A cute Halloween-themed budget team, especially if you have
                Bkornblume’s 1.2 skin.
              </li>
              <ul>
                <li>
                  Rabies applies a lot of [Poison] on his own with every attack.
                  His main weakness is his [Poison] only lasts 1 turn. Tuesday’s
                  extra [Poison] application and Array fix both of these
                  problems, allowing Rabies to act as a surprisingly decent
                  [Poison] applicator.
                </li>
                <li>
                  Bkornblume’s “Prying Ear” applies both Reality DEF Down and
                  DMG Taken Up to all enemies, making her an amazing enabler for
                  Tuesday’s passive [Poison] application. Her Reality DEF debuff
                  also helps Rabies do more damage. Finally, her Ultimate not
                  only applies [Seal] for even more [Poison] from Tuesday, but
                  also serves as a single-target nuke to help Rabies take down
                  bosses (since Rabies is mostly a mass attacker).
                </li>
                <li>
                  Sotheby is obviously a fantastic sustain for this team,
                  especially after her Euphoria buff. [Poison] application for
                  days.
                </li>
                <li>
                  Yenisei is an excellent budget healer. Her Rank 2 “Action
                  Taker” also applies a DMG Dealt debuff, which works with
                  Tuesday.
                </li>
                <li>
                  Kakania is a more aggressive sustain option. Rabies’ low DEF
                  actually helps Kakania build up Empathy faster in this case,
                  while she can tank for him to keep his squishy 4* self alive.
                  She also deals a ton of Genesis DMG that benefits greatly from
                  Tuesday’s debuffs.
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldTuesday;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Tuesday? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Tuesday is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
